import logo from './logo.png';
import tokenlist from './token_list.json';
import './App.css';
import { SwapWidget } from '@uniswap/widgets'
import '@uniswap/widgets/fonts.css'

const TOKEN_LIST = tokenlist.tokens
const PYM = '0x0bD49815EA8e2682220BCB41524c0dd10Ba71d41'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <a href='https://playermon.com'>
          <img src={logo} className="App-logo" alt="logo" />
        </a>
        <div className="Uniswap">
          <SwapWidget
            jsonRpcEndpoint='https://polygon-rpc.com'
            tokenList={TOKEN_LIST}
            defaultChainId="137"
            defaultInputTokenAddress="NATIVE"
            defaultInputAmount="1"
            defaultOutputTokenAddress={PYM} />
        </div>
      </header>
    </div>
  );
}

export default App;
